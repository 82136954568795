import { autoinject, bindable } from 'aurelia-framework';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { subscribe } from '../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { AlertService } from '../../services/util/Alert';
import { IDashboardItem, IDashboardSummaryConfig } from '../../types/Dashboards';
import { calculateStats } from './utils/calculateStats';

@subscribe({
    events: [{ eventEmitter: 'storeData', event: SimpleStore.change, fn: 'getStats' }],
    attached: 'storeAttached',
    detached: 'storeDetached',
})

@subscribe({
    events: [{
        eventEmitter: 'eaw', event: 'edit-item', fn: 'updateChart',
    }],
})

@autoinject
export class SummaryGadget {
    @bindable storeData: SimpleStore;
    @bindable summaryOptions: IDashboardItem<IDashboardSummaryConfig>;
    calculation: number;

    constructor(
        private eaw: EventAggregatorWrapper,
        private stores: StoreManager,
        private alerts: AlertService,
    ) {
        this.eaw = eaw;
    }
    unbind() {
        this.eaw.off('edit-item', 'updateChart');
    }

    activate(model) {
        this.summaryOptions = model.options;
        this.getStoreData();
    }

    storeAttached() {
        // Called and overriden in subscribe function
    }
    storeDetached() {
        // Called and overriden in subscribe function
    }

    getStoreData() {
        if (this.storeData) {
            this.storeDetached();
        }
        this.storeData = this.stores.getStore(this.summaryOptions.config.storeId) as SimpleStore;

        if (this.storeData) {
            this.storeAttached();
        }

        this.storeData?.refresh()
            .then((result) => {
                this.storeData = result;
                this.getStats();
            }).catch((err) => {
                this.storeData.loading = false;
                this.alerts.create({
                    level: 'error',
                    label: `Error fetching DataStore: ${this.storeData.name} (${err.message})`,
                    dismissable: true,
                });
            });

    }

    getStats() {
        this.calculation = calculateStats(this.storeData.data, this.summaryOptions.config.fieldSelection, this.summaryOptions.config?.statSelection);
        if (!this.calculation && this.calculation !== 0) {
            this.calculation = undefined;
        }

    }

    updateChart(data) {
        if (data.id === this.summaryOptions.id) {
            const newChartOptions = Object.assign({}, data.config);
            this.summaryOptions.config = newChartOptions;
            this.getStoreData();
        }
    }
}
